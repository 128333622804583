@import "./shared";

.products-form-container {
    padding: 30px;

    header {
        @include centerChilds;
        justify-content: space-between;

        span {
            cursor: pointer;
        }

        h3 {
            font-size: 2.3rem;
            margin: 0 auto;
        }
    }

    form {
        padding: 30px 0;

        article {
            @include centerChilds;

            @include lg {
                flex-wrap: wrap-reverse;
            }

            gap: 30px 0;

            .inputs-container {
                flex-grow: 1;
                display: flex;
                flex-flow: row wrap;
                gap: 20px;
                flex-basis: 50%;

                .form-control {
                    
                    &:first-of-type {
                        width: calc(90% + 20px);
                        margin-bottom: 50px;
                    }

                    &:not(:first-of-type) {
                        width: 45%;
                        min-width: 250px;
                        flex-grow: 1;
                       
                    }

                    .input {
                        border-radius: 6px;

                        span {
                            display: inline-block;
                        }

                        input {
                            background: transparent;
                            border: none;
                            width: 100%;
                        }
                    }
                }
            }

            .dropzone-wrapper {
                flex-grow: 1;
                min-width: 25%;
                @include centerChilds;
            }
        }


        .feiled-array-header {
            margin-top: 25px;
        }

        .add-bullet {
            margin-left: auto;
        }

        .submit-container {
            justify-content: flex-start;
        }
    }
}