@import './_shared';

.recent-work-form-container {
    form {
        div {
            &:first-of-type.form-control {
                @include firstFormControl;
            }
            &.feiled-array-container{
                margin-top: 10px;
            }
            &.submit-container {
                margin-top: 20px;
            }
        }
        
    }
}