@import './_shared';

.categories-form-container{
    form {
        div {
            &:first-of-type.form-control {
                @include firstFormControl;
            }
          
            &.submit-container {
                margin-top: 20px;
            }
        }
       
    }
}