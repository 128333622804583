@import './shared';

.side-bar-container {
    background: $primary-color;
    flex-basis: 25%;
    min-width: 270px;
    max-width: 270px;
    color: $text-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    $marginTop: 30px;

    header {
        margin-top: $marginTop;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        padding: 10px;
        gap: 10px;

        img {
            width: 100%;
            max-width: calc(80px + 1rem);
        }
    }

    .links {
        margin:$marginTop 0 auto 0;
        display: flex;
        flex-direction: column;

        a {
            color: $text-color;
            font-size: 1.2rem;
            padding: 15px;
            text-decoration: none;
            transition: all 0.5s ease-in-out;

            &.active {
                color: $primary-color;
                background: $text-color;
            }
        }
    }

    footer {
        margin-bottom: $marginTop;
        display: inline-block;
        @include centerChilds;

        button {
            font-size: 1rem;
            color: $text-color;
            outline: none;
            border: none;
            padding: 10px 15px;
            @include centerChilds;
            flex-flow: row nowrap;
            cursor: pointer;
            background: $logout-bg;
            border-radius: 30px;

            img {
                margin-inline-end: 10px;
            }
        }



    }
}