@import './shared';


.clients-form-container {
    form {
        div {
            &:first-of-type.form-control {

                @include firstFormControl;
                align-items: flex-end;
            }

            &.submit-container{
                margin-top: 20px;
            }
        }
    }
}