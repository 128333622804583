@import "./shared";

.service-form-container {
    color: $btn-bg;

    form {
        div {
            &:first-of-type.form-control {
                @include firstFormControl;
            }

            &.form-control {
                margin: 15px 0;

                .service-images-container{
                    display: flex;
                    justify-content: space-around;
                    p{
                        margin-block-end: 5px;
                    }
                }
            }
        }
    }
}