@import './shared';

.base-page {
    padding: 30px;

    header {
        margin-bottom: 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        gap: 20px;


        .product-search-filter-contaier {
            padding: 5px 10px;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px 10px;

            @include sm {
                flex-direction: column;
            }

            .filter-dropdown {

                min-width: 200px;
                border: none;
                filter: drop-shadow(1px 1px 1px black);
                outline: none;
                border-radius: 20px;

                span {
                    color: $btn-bg;

                    &.p-dropdown-trigger-icon {
                        padding: 10px 20px;
                    }

                }


                .p-inputtext {
                    padding: 10px 20px;
                }
            }

            span.p-input-icon-right {
                i {
                    margin-inline-end: 10px;
                    z-index: 1;
                }

                .search-input {

                    border: none;
                    filter: drop-shadow(1px 1px 1px black);
                    outline: none;
                    padding: 10px 20px;
                    border-radius: 20px;
                }
            }



            button {
                @include btnWithIcon;
            }
        }

        h3 {
            font-size: 2.6rem;
        }



    }

    .table-container {

        .table {
            margin-bottom: 30px;
            $radius: 10px;

            thead {
                tr {
                    th {
                        background: transparent;

                        &:first-child {
                            border-radius: $radius 0 0 0;
                        }

                        &:last-child {
                            border-radius: 0 $radius 0 0;
                        }
                    }
                }
            }

            tbody {
                &>tr:last-child {
                    &>td:first-child {
                        border-radius: 0 0 0 $radius;
                    }

                    &>td:last-child {
                        border-radius: 0 0 $radius 0;
                    }
                }
            }
        }
    }

    .data-loading-container {
        @include centerChilds;
        height: 100%;
    }

    .data-error-container {
        @include centerChilds;
        flex-direction: column;
        height: 100%;

        button {
            margin-top: 15px;
            cursor: pointer;
            @include btnStyle;
            border-radius: 30px;
            font-size: 1rem;
            padding: 10px 50px;
        }
    }



}


.p-datatable-emptymessage {
    td {
        text-align: center !important;
    }

}