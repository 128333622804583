@import "./shared";


@mixin afterStyle {
    color: $text-color;
    position: absolute;
    padding: 5px 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-size: 18px;
    background: $btn-bg;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}


.dropzone-container {
    background: $drop-zone-bg;
    border: 1px dashed $drop-zone-border;
    border-radius: 10px;
    padding: 10px;
    @include centerChilds;
    flex-direction: column;
    min-width: 125px;
    min-height: 125px;
    font-size: 10px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    img.icon {
        margin: 10px;
        max-width: 25px;
        width: 100%;
    }

    p {

        span {
            color: $primary-color;
            display: block;
        }
    }

    .preview {
        position: absolute;
        border-radius: inherit;
        inset: 0px;
        background: $drop-zone-bg;

        img {
            width: 100%;
        }

        &:not(.galleria):after {
            content: "Replace Image";
            @include afterStyle;
        }

        &.multiple:after {
            content: "Add More Images";
            @include afterStyle;
        }

        &:hover::after {
            opacity: 1;
        }

        .p-galleria-item-wrapper {
            width: 100%;
        }

        .p-galleria-item-wrapper,
        .p-galleria-item-container {

            position: static;
        }

        .p-galleria-indicators {
            position: absolute;
            bottom: -10px;
            left: 0;
            right: 0;
            z-index: 100;
        }

        .p-galleria-item-prev,
        .p-galleria-item-next {
            position: absolute;
            top: 75%;

            z-index: 100;
        }
    }


    &.error {
        border-color: $remove-item-bg;
    }

    &.big {
        font-size: 12px;
        min-width: 300px;
        min-height: 300px;
        max-width: 300px;

        img.icon {

            max-width: 40px;

        }
    }

    .preview-image-container {
        position: relative;
        width: 100%;

        img {
            width: 100%;
        }

        i {
            position: absolute;
            right: 10px;
            top: 10px;
            background:$btn-bg ;
            color: $text-color;
            padding: 5px;
            border-radius: 50%;
            font-size: smaller;
        }
    }
}