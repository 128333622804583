$login-bg:#0083b7b5;
$primary-color:#0083B7;
$logout-bg:#007aaa;
$text-color:#fff;
$btn-bg:#1E1E1E;
$table-border-color:#999999;
$drop-zone-bg:#FAFAFA;
$drop-zone-border:#444444;
$inputs-background:#F5F5F5;
$inputs-color:#CBCBCB;
$remove-item-bg:#FF4F4F;