@import './shared';

.loader-container{
    transition: all 0.5s ease-in-out;
    position: absolute;
    z-index: 5000;
    // width: 100vw;
    // height: 100vh;
    background: $login-bg;
    inset: 0;
    @include centerChilds;
    flex-direction: column;
    img{
        margin: 10px;
        width: 100%;
        max-width: 200px;
    }

}