* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


$sectionTitlesFontSize:3.5rem;
// Small phones
$screen-tn-max: 450px;
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 596px;
// Small tablets (portrait view)
$screen-md-max: 768px;
// Tablets and small desktops
$screen-lg-max: 992px;



// Phone devices
@mixin tn {
    @media (max-width: #{$screen-tn-max}) {
        @content;
    }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}



@mixin centerChilds {
    display: flex;
    justify-content: center;
    align-items: center;
}


@mixin setColorAndBackground($color, $bg) {
    color: $color;
    background: $bg;
}

@mixin setAsBackground {
    position: absolute;
    z-index: -1;
}

@mixin ellipsisText($numberOfLines:1) {
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $numberOfLines;
    -webkit-box-orient: vertical;
}


@mixin addBolts($size:10px) {
    content: "";
    position: absolute;
    width: $size;
    height: $size;
    border-radius: 50%;
    background: $primary-color;
    top: $size;
    left: calc(-1 * $size);
}


@mixin btnStyle {
    display: inline-block;
    outline: 0;
    border: 0;
    padding: 10px;
}

@mixin btnWithIcon {
    cursor: pointer;
    font-size: 1rem;
    border: none;
    outline: none;
    border-radius: 30px;
    padding: 10px 20px;
    color: $text-color;
    background: $btn-bg;
    @include centerChilds;
    align-items: baseline;

    i,
    span {
        margin-inline-end: 10px;
    }
}



/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;

}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    transition: 0.5s;
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


:root {
    font-size: 18px;
    font-family: Inter, Arial, Helvetica, sans-serif;
    scroll-behavior: smooth;


    @include lg {
        font-size: 18px;
    }

    @include md {
        font-size: 16px;
    }

    @include sm {
        font-size: 14px;
    }

}


.main-container {
    position: relative;
    height: 100vh;
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: auto;

    .side-bar-container {
        position: sticky;
        top: 0;

    }

    &>section:nth-child(2) {
        flex-grow: 1;

        padding-bottom: 120px;
    }
}


.center-header {
    div {
        justify-content: center;
    }
}

/* Customize Dialog */
.p-dialog-title {
    text-align: center;
}

.dialog-window {
    max-width: 600px;
    flex-grow: 1;

    &.confirm {
        max-width: 450px;

        .p-dialog-header {
            padding: 15px;
        }

        .p-dialog-header-close {
            display: none;
        }

        .content {
            text-align: center;
            padding: 10px 0px;
            font-size: 1rem;

            span {
                font-weight: 700;
                color: $remove-item-bg;
            }

            header,
            footer {
                font-weight: 700;
            }

            p {
                margin: 15px 0;
            }
        }

        .btns-container {
            margin-top: 10px;
            display: flex;
            justify-content: space-evenly;

            button {
                cursor: pointer;
                border: none;
                outline: none;
                padding: 5px 35px;
                border-radius: 20px;

                span {
                    margin-inline-end: 10px;
                }

                &.cancle {
                    background: $btn-bg;
                    color: $text-color;
                }

                &.confirm {
                    border: 1px solid $btn-bg;
                    background: $text-color;
                    color: $btn-bg;
                }
            }
        }
    }
}

/* General Form Styling */
.label {
    display: block;
    padding: 5px 0;
}


span.error {
    display: inline-block;
    font-size: smaller;
    color: $remove-item-bg;
    padding: 5px 0px;

}

.input {
    background: $inputs-background;

    border: 2px solid $btn-bg;
    display: block;
    width: 100%;

    &::placeholder {
        color: $inputs-color;
    }
}

.dropdown {
    border: 2px solid $btn-bg;
    background: $inputs-background;
    width: 100%;
    max-width: 100%;
    flex-grow: 1
}

.p-dropdown-items {
    background: $inputs-background;
}

.add-bullet {
    @include btnWithIcon;
    color: $btn-bg;
    background: $text-color;
    filter: drop-shadow(1px 1px 1px black);
    margin: 5px 0;
}

.feiled-array-header {
    @include centerChilds;
    justify-content: space-between;
    margin: 10px 0;

    button {
        @include btnWithIcon;
        margin: 0 5px;
        color: $btn-bg;
        background: $text-color;
        filter: drop-shadow(1px 1px 1px black);
    }
}
.feiled-array-container {
    max-height: 190px;
    overflow-y: auto;

    .feiled-array-item {

 

        .item {
            @include centerChilds;
            justify-content: space-between;
            margin: 10px 0;
            gap: 15px;

            input {
                flex-basis: 80%;
                flex-grow: 1;
            }

            button {
                outline: none;
                border: none;
                margin: 0 auto;
                background: $remove-item-bg;
                padding: 10px 15px;
                color: $text-color;
                font-weight: 900;
                border-radius: 30px;
                cursor: pointer;
            }
        }
    }
}


@mixin firstFormControl {
    @include centerChilds;
    gap: 10px;
    flex-wrap: wrap-reverse;
    align-items: flex-start;

    div:first-of-type {
        flex-grow: 1;
    }
}

.submit-container {
    @include centerChilds;

    button {
        @include btnWithIcon;
        padding: 10px 40px;

        &:hover {
            background: $btn-bg !important;
        }
    }
}

textarea {
    resize: none;

    min-height: 100px;

}