@import './shared';

.login-container {
    min-height: 100vh;
    position: relative;

    .background {

        position: absolute;
        inset: 0;
        z-index: -1;

        .left {
            width: 55%;
            background: white;
            position: relative;

            @include md {
                width: 100%;
            }

            img {
                width: 100%;
            }
        }


    }

    .form-container {
        overflow: hidden;
        background-color: $login-bg;
        position: absolute;
        inset: 0;
        width: 60%;
        margin-inline-start: auto;
        @include centerChilds;
        flex-direction: column;
        justify-content: space-evenly;
        color: $text-color;

        @include sm{
            width: 100%;
        }
        h3 {
            text-align: center;
            font-size: 3rem;
        }




        form {

            .form-control {
                display: flex;
                flex-direction: column;

                label {
                    margin: 10px 0;
                }


            }

            .btn-container {
                margin-top: 50px;
                @include centerChilds;
                flex-direction: column;

            
                button {
                    cursor: pointer;
                    @include btnStyle;
                    border-radius: 30px;
                    font-size: 1rem;
                    padding: 10px 50px;
                }
            }


        }

    }


}